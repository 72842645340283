import Icons from 'features/shared/components/icons';
import { ComponentProps } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

interface IMobileToggleProps
  extends Omit<ComponentProps<'button'>, 'children'> {
  expanded: boolean;
}

export function MobileToggle({ expanded, ...props }: IMobileToggleProps) {
  return (
    <button
      {...props}
      className={classNames(styles.toggleButton, {
        [styles.expanded]: expanded,
      })}
    >
      <Icons.ChevronUp />
    </button>
  );
}
