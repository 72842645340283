import { EntryFields } from 'contentful';
import { Usp } from 'src/features/pg-funnel/hooks/use-funnel-config/types';
import { Image } from 'src/features/pg-funnel/services/contentful/types';
import { ILink } from 'src/features/shared/contentful/types/ILink';

export enum PageHeaderBackgroundStyle {
  BackgroundImage = 'Background image',
  Collage = 'Collage',
  ColorYellow = 'Color Yellow',
  ColorSoftPeach = 'Color Soft-Peach',
  ColorSoftSand = 'Color Soft-Sand',
}

export interface IPageHeader {
  usps?: Usp[];
  id?: string;
  type: 'pageHeader';
  title: string;
  subtitle?: string;
  primaryButton?: ILink;
  secondaryButton?: ILink;
  description: EntryFields.RichText;
  collageImages: Image[];
  image: Image;
  mobileImage?: Image;
  hasFontColorLight: boolean;
  hasClubSearch: boolean;
  backgroundStyle: PageHeaderBackgroundStyle;
  mainImageSizes?: string;
  contentAlignment?: string;
}
