import * as R from 'ramda';
import { ClubWithCmsData } from 'src/features/pg-funnel/utils/funnel';
import { Club } from 'src/features/shared/types/Club';

export const getSortedClubs = (
  clubs: ClubWithCmsData[] | Club.Electrolyte[],
) => {
  return R.pipe(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    R.sortWith<any>([
      R.ascend(R.prop('name')),
      R.ascend(R.prop('geoDistance')),
      R.ascend(R.prop('geoDistanceFromSearch')),
    ]),
  )(clubs);
};
