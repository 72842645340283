import classNames from 'classnames';
import { useIntl } from 'src/domains/i18n';
import { ClubCard } from 'src/features/clubs-map-page/components/club-card';
import { Club } from 'src/features/shared/types/Club';
import styles from './index.module.scss';

interface Props {
  clubs?: Club.MapCard[];
  query?: string;
}

const ClubsList = ({ clubs, query }: Props) => {
  const { formatMessage } = useIntl();
  const count = clubs?.length;

  const renderTitle = () => {
    if (clubs && clubs.length === 0) {
      return (
        <>
          {formatMessage('clubs.club-list.title.search.no-results')}{' '}
          <strong>&quot;{query}&quot;</strong>
        </>
      );
    }

    return formatMessage(
      query
        ? count === 1
          ? 'clubs.club-list.title.search.singular'
          : 'clubs.club-list.title.search.plural'
        : 'clubs.club-list.title.initial',
      { count },
    );
  };

  return (
    <>
      <header
        className={classNames(styles.header, {
          [styles.textCenter]: count === 0,
        })}
      >
        <span>{renderTitle()}</span>
      </header>
      {clubs && clubs.length > 0 && (
        <ul className={styles.list}>
          {clubs.map((club: Club.MapCard) => (
            <li key={club.pgClubId} className={styles.listItem}>
              <ClubCard club={club} className={styles.card} />
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default ClubsList;
