import { useMemo, useState } from 'react';
import { useGeolocation } from 'src/features/shared/hooks/use-geo-location';
import { Club } from 'src/features/shared/types/Club';
import { getSortedClubs } from 'src/features/shared/utils/get-sorted-clubs';

interface IUseClubsMapProps<T> {
  clubs: T[];
  searchResults?: T[];
  clubsNearbyResults?: T[];
  selectedClubId?: number;
  searchNearby?: boolean;
}

export function useClubsMap<T extends Club.Electrolyte>({
  clubs,
  searchResults,
  clubsNearbyResults,
  selectedClubId,
  searchNearby = false,
}: IUseClubsMapProps<T>) {
  const [mobileSheetExpanded, setMobileSheetExpanded] = useState(false);

  const selectedClub = useMemo(
    () => clubs.find((club: T) => club.pgClubId === selectedClubId),
    [selectedClubId, clubs],
  );

  const { latitude, longitude } = useGeolocation({}, searchNearby);
  const geolocation =
    latitude && longitude ? { lat: latitude, lng: longitude } : undefined;

  const listResults = clubsNearbyResults?.length
    ? clubsNearbyResults
    : searchResults?.length
    ? searchResults
    : getSortedClubs(clubs);

  const markersData = (
    listResults && listResults?.length > 0 ? listResults : clubs
  ).map((item) => ({ ...item, id: item.pgClubId }));

  return {
    mobileSheetExpanded,
    setMobileSheetExpanded,
    selectedMarker: selectedClubId,
    selectedClub,
    markersData,
    listResults,
    geolocation,
  };
}
