import { useIntl } from 'src/domains/i18n'

export const useFormattedPrice = (price: number | string) => {
  const { formatNumberToParts } = useIntl()

  const formattedPrice: [{ value: string, type: string }] = formatNumberToParts(price, {
    style: 'currency',
    currency: 'EUR'
  })
  const integer = formattedPrice.find(({ type }) => type === 'integer')?.value || '0'
  const fraction = formattedPrice.find(({ type }) => type === 'fraction')?.value || '00'
  const decimal = formattedPrice.find(({ type }) => type === 'decimal')?.value || ','

  return {
    formattedPrice: `${integer}${decimal}${fraction}`,
    integer: integer.toString(),
    fraction: fraction.toString(),
    fractionWithDecimal: decimal + fraction
  }
}
