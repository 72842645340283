import { getGroupLessonsData } from '@features/group-lessons/services/proxy';
import { contentPageKeys } from '@features/shared/react-query-keys/content-page';
import { groupLessonsKeys } from '@features/shared/react-query-keys/group-lessons';
import theme from 'config/theme';
import { ClubPage } from 'features/club-page/components';
import { QueryClient, dehydrate } from 'react-query';
import { CONTENT_TYPES } from 'services/contentful/constants';
import { ClubsMapPage } from 'src/features/clubs-map-page/components';
import { getCheckoutClubs, getClubById } from '../src/services/proxy';

import { fetchFooterData } from '@features/layout/footer/services/proxy/fetch-footer-data';
import { footerDataKeys } from '@features/shared/react-query-keys/footer';
import { GetServerSideProps } from 'next';
import { useEffect } from 'react';
import { Footer } from 'src/features/layout/footer/components';
import { Header } from 'src/features/layout/header/Header';
import { IMappedMenus } from 'src/features/layout/menu/types/IMappedMenus';
import { ContentPageView } from 'src/features/shared/components/content-page-view';
import { IClubContentPage } from 'src/features/shared/contentful/types/IClubContentPage';
import { IContentPage } from 'src/features/shared/contentful/types/IContentPage';
import { fetchHeaderMenusProxy } from 'src/features/shared/services/proxy/fetchHeaderMenus';
import { Club } from 'src/features/shared/types/Club';
import { deepRemoveUndefined } from 'src/features/shared/utils/deep-remove-undefined';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import { getPageType } from 'src/features/shared/utils/ga4/get-page-type';
import { fetchCheckout } from 'src/services/contentful/fetch-checkout';
import { fetchPage } from 'src/services/contentful/fetch-page';

interface IContentPageProps {
  pageSlug: string;
  isPreview: boolean;
  search: string;
  club: Club.Details;
  page: IContentPage;
  headerMenus: IMappedMenus;
  mapClubs: Club.MapCard[];
}

export default function ContentPage({
  pageSlug,
  isPreview,
  club,
  page,
  headerMenus,
  mapClubs,
}: IContentPageProps) {
  const isSportCity = theme.name === 'sportcity';

  useEffect(() => {
    if (page) {
      const slug =
        page.type === CONTENT_TYPES.CLUB_PAGE
          ? 'Club'
          : page.type === CONTENT_TYPES.CLUB_OVERVIEW_PAGE
          ? 'Zoeken'
          : pageSlug;
      pushGA4Event(GA4_EVENTS.pageData, {
        page_type: page.pageType || getPageType(slug),
      });
    }
  }, [page, pageSlug, page.pageType, page.type]);

  if (!page) {
    return null;
  }

  const renderPage = () => {
    switch (page && page.type) {
      case CONTENT_TYPES.PAGE:
        return <ContentPageView page={page} />;
      case CONTENT_TYPES.CLUB_PAGE:
        return <ClubPage slug={pageSlug} club={club} isPreview={isPreview} />;
      case CONTENT_TYPES.CLUB_OVERVIEW_PAGE:
        return <ClubsMapPage clubs={mapClubs} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Header
        menus={headerMenus}
        temporaryOffer={page.temporaryOffer}
        clubId={page.pgClubId}
        isAlternative={isSportCity}
      />
      {renderPage()}
      {page.type !== CONTENT_TYPES.CLUB_OVERVIEW_PAGE && <Footer />}
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async ({
  query,
  res,
  locale,
  defaultLocale,
}) => {
  const { slug, preview, search } = query;

  if (res && !preview) {
    res.setHeader(
      'Cache-Control',
      'public, s-maxage=3600, stale-while-revalidate=3600',
    );
  }

  const queryClient = new QueryClient();

  const isPreview = preview === 'true';
  const lang = locale || defaultLocale || 'nl';

  // checking if 'nl' is seen as slug, which happens if a query is sent along to the homepage (/?abc=def)
  const pageSlug = (
    !slug || slug[0] === 'nl'
      ? 'home'
      : typeof slug === 'object'
      ? slug.join('/')
      : slug
  ).toLowerCase();

  let contentPage = null;

  try {
    contentPage = await fetchPage(pageSlug, lang, isPreview);
  } catch (error) {
    res.statusCode = 404;
    return {
      notFound: true,
    };
  }
  let club: Club.Details | undefined;
  if (contentPage && contentPage.type === 'clubPage') {
    const { pgClubId } = contentPage as IClubContentPage;

    const clubResponse = await getClubById(pgClubId);
    club = clubResponse.data;

    await queryClient.prefetchQuery(
      groupLessonsKeys.list(pgClubId.toString()),
      async () => {
        const res = await getGroupLessonsData({ clubId: pgClubId });
        return res.data;
      },
    );

    await queryClient.prefetchQuery(
      contentPageKeys.detailBySlug(pageSlug),
      async () => fetchPage(pageSlug, lang, isPreview),
    );
  }

  let mapClubs: Club.Details[] | undefined;
  if (contentPage && contentPage.type === 'clubOverviewPage') {
    const checkoutCMS = await fetchCheckout('default', lang, isPreview);
    mapClubs = checkoutCMS
      ? await getCheckoutClubs(checkoutCMS.config.id)
      : undefined;
  }

  await queryClient.prefetchQuery(
    footerDataKeys.primaryFooterByLang(lang),
    async () => fetchFooterData(lang),
  );

  const headerMenus = await fetchHeaderMenusProxy();

  const props = deepRemoveUndefined({
    pageSlug,
    isPreview,
    headerMenus,
    search,
    club,
    dehydratedState: dehydrate(queryClient),
    page: contentPage,
    mapClubs,
  });
  return {
    props,
  };
};
