import classNames from 'classnames';
import { UseMyLocationButton } from 'features/shared/components/use-my-location-button';
import { useRouter } from 'next/router';
import { KeyboardEvent, useState } from 'react';
import { useIntl } from 'src/domains/i18n';
import { Link } from 'src/features/shared/components/link';
import { SearchButton } from 'src/features/shared/components/search-button';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import useBreakpoints from 'src/utils/hooks/use-breakpoints';
import styles from './ClubSearchInput.module.scss';

interface ClubSearchInputProps {
  isLightTheme: boolean;
}

export function ClubSearchInput({ isLightTheme }: ClubSearchInputProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const { formatMessage } = useIntl();
  const router = useRouter();
  const { currentBreakpoint, breakpoints } = useBreakpoints();
  const isMobile = currentBreakpoint === breakpoints.xs.name;

  const onSubmit = () => {
    pushGA4Event(GA4_EVENTS.search, {
      search_term: searchQuery,
      seearch_type: 'default',
    });

    router.push(`/clubs?searchQuery=${searchQuery}`);
  };
  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSubmit();
    }
  };

  return (
    <div
      className={classNames(styles.body, { [styles.lightTheme]: isLightTheme })}
    >
      <div className={styles.inputContainer}>
        <input
          onKeyDown={onKeyDown}
          id="search-box"
          className={styles.input}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder={formatMessage(
            'video-page-header.searchInput.placeholder',
          )}
        />
        <SearchButton
          onClick={onSubmit}
          type="button"
          className={styles.searchButton}
        />
      </div>

      <div className={styles.links}>
        {!isMobile && (
          <div>
            <span>{formatMessage('video-page-header.cities-links.label')}</span>
            <Link href="/clubs?searchQuery=amsterdam">
              {formatMessage('video-page-header.cities-links.amsterdam')}
            </Link>
            <span>, </span>
            <Link href="/clubs?searchQuery=Den%20Haag">
              {formatMessage('video-page-header.cities-links.den-haag')}
            </Link>
            <span>, </span>
            <Link href="/clubs?searchQuery=Leiden">
              {formatMessage('video-page-header.cities-links.leiden')}
            </Link>
            <span>, </span>
            <Link href="/clubs?searchQuery=Rotterdam">
              {formatMessage('video-page-header.cities-links.rotterdam')}
            </Link>
            <span>, </span>
            <Link href="/clubs?searchQuery=Utrecht">
              {formatMessage('video-page-header.cities-links.utrecht')}
            </Link>
          </div>
        )}
        <UseMyLocationButton
          href={'/clubs?searchNearby=true&searchQuery=In%20de%20buurt'}
          className={styles.locationButton}
          isLight
        />
      </div>
    </div>
  );
}
