import { useEffect, useState } from 'react';
import {Input} from 'src/features/shared/components/input';
import Icons from 'features/shared/components/icons';
import styles from './index.module.scss';
import { useIntl } from 'src/domains/i18n';

interface IClubsSearchFormProps {
  defaultQuery?: string;
  onSubmit: (query: string) => void;
}

export function ClubsSearchForm({
  onSubmit,
  defaultQuery,
}: IClubsSearchFormProps) {
  const formatMessage = useIntl().formatMessage;
  const [query, setQuery] = useState(defaultQuery ?? '');

  useEffect(() => {
    setQuery(defaultQuery ?? '');
  }, [defaultQuery]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(query);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleClear = () => {
    setQuery('');
    onSubmit('');
  };

  return (
    <form onSubmit={handleSubmit} className={styles.clubSearchForm}>
      <Input
        isLight
        value={query}
        placeholder={formatMessage('clubs.search.placeholder')}
        onChange={handleInputChange}
        autoComplete="off"
        id="search-box"
        className={styles.searchInput}
      />
      {defaultQuery ? (
        <button
          type="button"
          className={styles.inputButton}
          onClick={handleClear}
        >
          <Icons.Cross />
        </button>
      ) : (
        <button type="submit" className={styles.inputButton}>
          <Icons.Search />
        </button>
      )}
    </form>
  );
}
