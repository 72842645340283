import { Club } from 'src/features/shared/types/Club';
import { IGeolocation } from 'src/models/IGeolocation';
import { addDistanceToClubs } from 'src/utils/add-distance-to-clubs';
import getClosestClubs from 'src/utils/get-closest-clubs';

export function getClubsByGeolocation<T extends Club.Electrolyte>(
  clubs: T[],
  geolocation: IGeolocation,
  maxResults: number | null,
): T[] {
  const clubsWithGeoDistance = addDistanceToClubs<T>(
    clubs,
    geolocation,
    'geoDistanceFromSearch',
  );

  return getClosestClubs<T[]>(
    clubsWithGeoDistance,
    maxResults,
    'geoDistanceFromSearch',
  );
}
