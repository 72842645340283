import classNames from 'classnames';
import styles from './index.module.scss';
import useBreakpoints from 'src/utils/hooks/use-breakpoints';

interface IMobileBottomSheetProps {
  expanded: boolean;
  children: React.ReactNode;
}

export function MobileBottomSheet({
  expanded,
  children,
}: IMobileBottomSheetProps) {
  const { currentBreakpoint } = useBreakpoints();

  if (currentBreakpoint !== 'xs') {
    return <>{children}</>;
  }

  return (
    <div
      className={classNames(styles.container, { [styles.expanded]: expanded })}
    >
      {children}
    </div>
  );
}
