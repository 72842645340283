import Icons from 'features/shared/components/icons';
import { useIntl } from 'src/domains/i18n';
import { ClubCard } from 'src/features/clubs-map-page/components/club-card';
import { Club } from 'src/features/shared/types/Club';
import styles from './index.module.scss';

interface IClubSingle {
  club: Club.MapCard;
  onClose: () => void;
}

export function ClubSingle({ club, onClose }: IClubSingle) {
  const { formatMessage } = useIntl();
  return (
    <>
      <header className={styles.header}>
        <span>{formatMessage('clubs.club-list.title.selected')}</span>
        <button onClick={onClose} className={styles.closeButton}>
          <Icons.Cross />
        </button>
      </header>
      <main className={styles.main}>
        <ClubCard club={club} className={styles.card} />
      </main>
    </>
  );
}
