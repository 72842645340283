import { useCallback, useEffect, useState } from 'react';
import { Club } from 'src/features/shared/types/Club';
import { getClubSearchResults } from '../util/get-club-search-results';

interface ClubSearchProps<T> {
  clubs: T[];
  maxResults?: number;
  query: string;
  enabled?: boolean;
}

/**
 * This hook returns the clubs that match the search query.
 * 1. It will try to transform the query into a location and search for clubs nearby.
 * 2. It will search for club names that match the query.
 */
export function useClubSearch<T extends Club.Electrolyte>({
  clubs,
  maxResults = 5,
  query,
  enabled,
}: ClubSearchProps<T>): { query: string; results: T[]; isLoading: boolean } {
  const [lastQuery, setLastQuery] = useState(query ?? '');
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState<T[]>(clubs);

  const search = useCallback(async () => {
    setIsLoading(true);

    try {
      const results = query
        ? await getClubSearchResults(clubs, query, maxResults)
        : clubs;
      setLastQuery(query ?? '');
      setResults(results as T[]);
    } catch (error) {
      setResults([]);
    } finally {
      setIsLoading(false);
    }
  }, [clubs, query, maxResults]);

  useEffect(() => {
    if (enabled) {
      search();
    }
  }, [enabled, search]);

  return {
    query: lastQuery,
    results,
    isLoading,
  };
}
