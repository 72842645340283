import { useIntl } from 'src/domains/i18n';
import styles from './index.module.scss';

export function ErrorMessage({ error }: { error: GeolocationPositionError }) {
  const { formatMessage } = useIntl();

  let message = error.message;

  if (error.PERMISSION_DENIED) {
    message = formatMessage('use-my-location-button.error.permission-denied');
  }

  return <div className={styles.container}>{message}</div>;
}
