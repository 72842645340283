export const getPageType = (url: string, prefix?: string): string | null => {
  if (!url) return null

  const urlRoot = url.split('/')[0]

  switch (urlRoot.toLowerCase()) {
    case 'default':
      return 'Funnel_default'

    case 'welkom':
      return `${prefix}_welkom`

    case 'kies-je-club':
    case 'selecteer-een-club':
      return `${prefix}_club`

    case 'kies-je-lidmaatschap':
      return `${prefix}_lidmaatschap`

    case 'persoonlijke-info':
      return `${prefix}_gegevens`

    case 'kies-je-extras':
      return `${prefix}_extras`

    case 'overzicht':
      return `${prefix}_overzicht`

    case 'betaling-mislukt':
      return 'Betaling_mislukt'

    case 'betaling-gelukt':
      return 'Betaling_succesvol'

    case 'blog':
    case 'club':
    case 'zoeken':
    case 'groepslessen':
      return urlRoot.charAt(0).toUpperCase() + urlRoot.slice(1)

    default:
      return 'Algemeen'
  }
}
