import { useAllClubs } from 'domains/clubs/hooks';
import { useIntl } from 'domains/i18n';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ClubSearchInput } from 'src/features/shared/components/club-search-input';
import { useGeolocation } from 'src/features/shared/hooks/use-geo-location';
import useClubSearch from 'utils/hooks/use-club-search';
import useLang from 'utils/hooks/use-lang';
import styles from './index.module.scss';

export default function ClubSearchBarSectionView({
  hasFontColorLight,
  setFilteredClubs,
  search,
  redirect,
  useMyLocation,
  inputPlaceholder,
}) {
  const { lang } = useLang();
  const router = useRouter();
  const { formatMessage } = useIntl();
  const [enableGeoLocation, setEnableGeoLocation] = useState(false);
  const [clubs] = useAllClubs(enableGeoLocation);
  const geoLocation = useGeolocation(undefined, enableGeoLocation);
  const { setClosestClubs } = useClubSearch(setFilteredClubs, clubs, 5);
  const [searchTerm, setSearchTerm] = useState('');

  const isLightTheme = useMemo(() => {
    return hasFontColorLight !== false;
  }, [hasFontColorLight]);

  const handleInputChange = useCallback(
    (event) => {
      setSearchTerm(event.target.value);
    },
    [setSearchTerm],
  );

  const onSearch = useCallback(async () => {
    if (redirect) {
      return await router.push('/[slug]', `/clubs?searchQuery=${searchTerm}`);
    }
    await setClosestClubs(searchTerm);
  }, [setClosestClubs, searchTerm, redirect, lang]);

  const onClickUseMyLocation = useCallback(
    async (useMyLocation) => {
      if (redirect) {
        return router.push(
          '/[slug]',
          `/clubs?searchNearby=true&searchQuery=${formatMessage(
            'club-detail.clubs-nearby.query',
          )}`,
        );
      }
      setEnableGeoLocation();
    },
    [useMyLocation],
  );

  const onClear = useCallback(() => {
    if (setFilteredClubs) {
      setFilteredClubs(null);
    }
    setSearchTerm('');
  }, [setFilteredClubs, setSearchTerm]);

  useEffect(() => {
    if (searchTerm && clubs?.length) {
      setSearchTerm(searchTerm);
    }
  }, [search, setSearchTerm, clubs, searchTerm]);

  return (
    <div className={styles.container}>
      <ClubSearchInput
        value={searchTerm}
        onChange={handleInputChange}
        onClear={onClear}
        onSubmit={onSearch}
        isAlternative={isLightTheme}
        placeholder={
          inputPlaceholder ||
          formatMessage('checkout.select-club.search.placeholder')
        }
        useMyLocationButtonProps={{
          isLoading: geoLocation.isLoading,
          error: geoLocation.error,
          onClick: onClickUseMyLocation,
        }}
      />
    </div>
  );
}

ClubSearchBarSectionView.propTypes = {
  hasFontColorLight: PropTypes.bool.isRequired,
  setFilteredClubs: PropTypes.func,
  search: PropTypes.string,
  redirect: PropTypes.bool,
  useMyLocation: PropTypes.string,
  inputPlaceholder: PropTypes.string,
};
