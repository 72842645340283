import { useRouter } from 'next/router';
import { useIntl } from 'src/domains/i18n';
import { useClubSearch } from 'src/features/checkout/hooks/use-club-search';
import { useClubsNearby } from 'src/features/checkout/hooks/use-clubs-nearby';
import { useClubsMap } from 'src/features/clubs-map-page/hooks/use-clubs-map';
import { ClubsMap } from 'src/features/shared/components/clubs-map';
import { Club } from 'src/features/shared/types/Club';
import { deepRemoveUndefined } from 'src/features/shared/utils/deep-remove-undefined';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import useBreakpoints from 'src/utils/hooks/use-breakpoints';
import * as yup from 'yup';
import { ClubSingle } from './club-single';
import ClubsList from './clubs-list/ClubsList';
import { ClubsSearchForm } from './clubs-search-form/ClubsSearchForm';
import { ErrorMessage } from './error-message';
import styles from './index.module.scss';
import { Loading } from './loading';
import { MobileBottomSheet } from './mobile-bottom-sheet';
import { MobileToggle } from './mobile-toggle/MobileToggle';

const searchParamsSchema = yup.object({
  searchNearby: yup.boolean().optional(),
  searchQuery: yup.string().optional(),
  selectedClubId: yup.number().optional(),
});

interface ClubsMapPageProps {
  clubs: Club.MapCard[];
}

export function ClubsMapPage({ clubs }: ClubsMapPageProps) {
  const { formatMessage } = useIntl();
  const router = useRouter();
  const { currentBreakpoint, breakpoints } = useBreakpoints();
  const isMobile = currentBreakpoint === breakpoints.xs.name;

  const { searchNearby, searchQuery, selectedClubId } =
    searchParamsSchema.validateSync(router.query);

  const search = useClubSearch({
    clubs,
    query: searchQuery || '',
    enabled: !searchNearby,
  });

  const clubsNearby = useClubsNearby({
    clubs,
    enabled: !!searchNearby,
  });

  const map = useClubsMap({
    clubs,
    searchResults: search.results,
    clubsNearbyResults: clubsNearby.results,
    selectedClubId,
    searchNearby,
  });

  const submit = (params: yup.InferType<typeof searchParamsSchema>) => {
    const query = deepRemoveUndefined({
      searchNearby,
      searchQuery,
      selectedClubId,
      ...params,
    });
    router.push(
      {
        pathname: '/clubs',
        query,
      },
      undefined,
      { shallow: true },
    );
  };

  const handleMarkerClick = (value: number) => {
    submit({ selectedClubId: value });
    map.setMobileSheetExpanded(true);
    if (value) {
      pushGA4Event(GA4_EVENTS.trackEvent, {
        event_name: 'click_location_map',
      });
    }
  };

  const handleQuerySubmit = (value: string) => {
    submit({
      searchQuery: value || undefined,
      selectedClubId: undefined,
      searchNearby: undefined,
    });
    map.setMobileSheetExpanded(value ? true : false);
  };

  const handleCloseSelectedCard = () => {
    submit({ selectedClubId: undefined });
    map.setMobileSheetExpanded(false);
  };

  const togleMobileSheet = () => {
    map.setMobileSheetExpanded(!map.mobileSheetExpanded);
  };

  const onSearchNearby = () => {
    submit({
      searchNearby: true,
      selectedClubId: undefined,
      searchQuery: formatMessage('club-detail.clubs-nearby.query'),
    });
  };

  const renderMain = () => {
    if (map.selectedClub) {
      return (
        <div className={styles.resultsContainer}>
          <ClubSingle
            club={map.selectedClub}
            onClose={handleCloseSelectedCard}
          />
        </div>
      );
    }

    if (clubsNearby.enabled && clubsNearby.error) {
      return <ErrorMessage error={clubsNearby.error} />;
    }

    if (clubsNearby.isLoading || search.isLoading) {
      return <Loading />;
    }

    return (
      <ClubsList
        key={search.query}
        clubs={map.listResults}
        query={search.query}
      />
    );
  };

  return (
    <div className={styles.pageContainer}>
      <MobileBottomSheet expanded={map.mobileSheetExpanded}>
        <div className={styles.searchPanelContainer}>
          <div className={styles.panelHeader}>
            <ClubsSearchForm
              defaultQuery={searchQuery}
              onSubmit={handleQuerySubmit}
            />

            <MobileToggle
              expanded={map.mobileSheetExpanded}
              onClick={togleMobileSheet}
            />
          </div>

          <div className={styles.panelMain}>
            <div className={styles.resultsContainer}>{renderMain()}</div>
          </div>
        </div>
      </MobileBottomSheet>

      <ClubsMap
        padding={
          /**
           * Gives padding on desktop because of the sidebar
           */
          isMobile ? undefined : { left: 360, top: 72, right: 72, bottom: 72 }
        }
        clubs={map.markersData}
        selectedClubId={selectedClubId}
        onMarkerClick={handleMarkerClick}
        userLocation={map.geolocation}
        onEnableUserLocation={onSearchNearby}
      />
    </div>
  );
}
