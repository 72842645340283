import { Loading as LoadingAnimation } from 'src/features/shared/components/loading';
import styles from './index.module.scss';

export function Loading() {
  return (
    <div className={styles.container} data-testid="loading-animation">
      <LoadingAnimation />
    </div>
  );
}
