import { removeDuplicateClubs } from 'src/features/pg-funnel/utils/remove-duplicate-clubs';
import { getLocationByAddress } from 'src/features/shared/services/geolocation/get-location-by-address';
import { Club } from 'src/features/shared/types/Club';
import { addDistanceToClubs } from 'src/utils/add-distance-to-clubs';
import getClosestClubs from 'src/utils/get-closest-clubs';
import { getFilteredClubs } from 'src/utils/get-filtered-clubs';
import { getClubsByGeolocation } from './get-clubs-by-geolocation';

const GEO_DISTANCE_KEY = 'geoDistanceFromSearch';

export async function getClubSearchResults<T extends Club.Electrolyte>(
  clubs: T[],
  query: string,
  maxResults: number,
) {
  const location = await getLocationByAddress(query);
  const geolocationResults = location
    ? await getClubsByGeolocation(clubs, location, maxResults)
    : [];

  // Filter clubs by query
  let queryResults = getFilteredClubs(clubs || [], query);

  if (location) {
    const queryResultsWithDistance = addDistanceToClubs<T>(
      queryResults,
      location,
      GEO_DISTANCE_KEY,
    );
    queryResults = getClosestClubs(
      queryResultsWithDistance,
      null,
      GEO_DISTANCE_KEY,
    );
  }

  return removeDuplicateClubs([
    ...queryResults,
    ...geolocationResults,
  ]) as Club.Electrolyte[];
}
